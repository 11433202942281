import Metadata from 'components/Metadata';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { item, projects } from './IndexTpl.module.scss';

const IndexTpl = ({ data }) => {
  const {
    landingPage: {
      title,
      meta: { description },
    },
  } = data;
  return (
    <div>
      <Metadata title={title} description={description} />
      <div className={projects}>
        <Link className={item} to="/projects/caves">台灣洞穴的感知敘事</Link>
        <Link className={item} to="/projects/atomatic-swap">原子交換鏈計畫</Link>
      </div>
    </div>
  );
};

IndexTpl.propTypes = {
  data: PropTypes.object,
};

export default IndexTpl;
